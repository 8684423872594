export default function useSystemPages() {
  const systemPages = computed<SystemPage[]>(() => {
    return [
      { id: 1, title: 'Vacaturebank', path: '/vacancies/search', identifier: 'vacancy-search' },
      {
        id: 2,
        title: 'Organisatie registereren',
        path: '/organizations/signup',
        identifier: 'organization-signup',
      },
      { id: 3, title: 'Partners overzicht', path: '/gelderse-handen', identifier: 'partners-overview' },
      // TODO: typo in path. However, I'm not sure if this will break anything.
      // I need to find a way to update content links throughout the app. Or a redirect maybe?
      { id: 4, title: 'Evenementen overzicht', path: '/evenmenten', identifier: 'events-search' },
      { id: 5, title: '1:1 hulp overzicht', path: '/een-op-een-hulp', identifier: 'one-on-one-search' },
      { id: 6, title: 'Activiteitenkalender', path: '/activiteitenkalender', identifier: 'activity-calendar' },
      { id: 7, title: 'Workshops overzicht', path: '/workshops', identifier: 'workshop-search' },
      { id: 8, title: 'Inloggen', path: '/auth/login-1', identifier: 'login' },
      { id: 9, title: 'Wachtwoord vergeten', path: '/auth/recover', identifier: 'password-recovery' },
      { id: 10, title: 'Vrijwilliger registereren', path: '/signup', identifier: 'volunteer-signup' },
      { id: 11, title: 'Sociale kaart', path: '/sociale-kaart', identifier: 'social-map' },
    ]
  })

  const findSystemPageByTitle = (title: string) => {
    return systemPages.value.find(page => page.title == title)
  }

  const findSystemPageById = (id: id) => {
    return systemPages.value.find(page => page.id == id)
  }

  const findSystemPageByIdentifier = (identifier: string) => {
    return systemPages.value.find(page => page.identifier == identifier)
  }

  return {
    systemPages,
    findSystemPageByTitle,
    findSystemPageById,
    findSystemPageByIdentifier,
  }
}
